<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="menDianMC" label="门店名称" />
            <x-detail-item prop="suoShuXingYe" label="所属行业" />
            <x-detail-item prop="jingYingLX" label="经营类型" />
            <x-detail-item prop="gongHuoShangLX" label="供货商类型" />
            <x-detail-item prop="menTouZhaoPian" label="门头照片" />
            <x-detail-item prop="shengDaiMa" label="省代码" />
            <x-detail-item prop="shiDaiMa" label="市代码" />
            <x-detail-item prop="quDaiMa" label="区代码" />
            <x-detail-item prop="menDianDiTuXX" label="门店地图信息" />
            <x-detail-item prop="menDianDiZhiXX" label="门店地址信息" />
            <x-detail-item prop="jingDu" label="经度" />
            <x-detail-item prop="weiDu" label="维度" />
            <x-detail-item prop="xiangXiDZ" label="详细地址" />
            <x-detail-item prop="guiShuShangHu" label="归属商户" />
            <x-detail-item prop="shiFuZhuDian" label="是否主店" />
            <x-detail-item prop="shiFuKaiQi" label="是否开启" />
            <x-detail-item prop="shiFuShanChu" label="是否删除" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/mdgl/MenDianXX";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                menDianMC: "", // 门店名称
                suoShuXingYe: "", // 所属行业
                jingYingLX: "", // 经营类型
                gongHuoShangLX: "", // 供货商类型
                menTouZhaoPian: "", // 门头照片
                shengDaiMa: "", // 省代码
                shiDaiMa: "", // 市代码
                quDaiMa: "", // 区代码
                menDianDiTuXX: "", // 门店地图信息
                menDianDiZhiXX: "", // 门店地址信息
                jingDu: "", // 经度
                weiDu: "", // 维度
                xiangXiDZ: "", // 详细地址
                guiShuShangHu: "", // 归属商户
                shiFuZhuDian: "", // 是否主店
                shiFuKaiQi: "", // 是否开启
                shiFuShanChu: "", // 是否删除
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>